import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  rightSidebarClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  sampleOrderRightSidebarClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  rightSidebarQuoteClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  rightSidebarFormatAndSizeClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  rightSidebarPriceListQuoteClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  leftSidebarSurfaceClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  resetAccessoryTotal: EventEmitter<boolean> = new EventEmitter<boolean>();
  accessorySizeChange: EventEmitter<number> = new EventEmitter<number>();

  emitRightSidebarClick(eventData: boolean) {
    this.rightSidebarClick.emit(eventData);
  }

  emitSampleOrderRightSidebarClick(eventData: boolean) {
    this.sampleOrderRightSidebarClick.emit(eventData);
  }

  emitRightSidebarQuoteClick(eventData: boolean) {
    this.rightSidebarQuoteClick.emit(eventData);
  }

  emitRightSidebarFormatAndSizeClick(eventData: boolean) {
    this.rightSidebarFormatAndSizeClick.emit(eventData);
  }

  emitRightSidebarPriceListQuoteClick(eventData: boolean) {
    this.rightSidebarPriceListQuoteClick.emit(eventData);
  }

  emitLeftSidebarSurfaceClick(eventData: boolean) {
    this.leftSidebarSurfaceClick.emit(eventData);
  }

  emitResetAccessoryTotal(eventData: boolean) {
    this.resetAccessoryTotal.emit(eventData);
  }

  emitAccessorySizeChange(eventData: number) {
    this.accessorySizeChange.emit(eventData);
  }
}
