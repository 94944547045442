import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {BaseService} from "@coreutils";
import {PaginationModel, ProductAccessoriesModel, ProductModel, RequestParamModel} from "@coremodels";
import {Observable} from "rxjs";
import {HttpClient} from '@angular/common/http';
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private baseService: BaseService, public Http: HttpClient) {
  }

  // get all in one product
  getAllInOneProduct(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.PRODUCT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // Get products
  getProduct(requestParamModel: RequestParamModel): Observable<any> {
    return this.baseService.get(ApiConstants.PRODUCT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // get product details.
  getDetails(productID: number, requestParamModel?: RequestParamModel): Observable<ProductModel> {
    return this.baseService.get(ApiConstants.PRODUCT_DETAIL_URL + productID, requestParamModel)
      .pipe(map<any, ProductModel>(response => {
        return response.body.data as ProductModel;
      }, (err: any) => {
        return err;
      }));
  }

  // get product accessories details.
  getAccessoriesFormatDetails(productID: number): any {
    return this.baseService.get(ApiConstants.PRODUCT_ACCESSORIES_FORMAT_URL + productID)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  getFilterAccessoriesProductDropdown(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.PRODUCT_FILTER_ACCESSORIES_DROPDOWN_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // get product accessories details.
  getAccessoriesTypeDetails(productID: number): any {
    return this.baseService.get(ApiConstants.PRODUCT_ACCESSORY_TYPE_URL + productID)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<any>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  getFilterAccessoriesTypeDropdown(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.PRODUCT_FILTER_ACCESSORIES_TYPE_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = response.body.data as any[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  download(bodyData: any) {
    return this.baseService.post(ApiConstants.PRODUCT_DOWNLOAD_URL, bodyData)
      .pipe(map<any, any>(response => {
        return response.data;
      }, (err: any) => {
        return err;
      }));
  }

  downloadFileContent(bodyData: any) {
    return this.baseService.getDownloadFileContent(`product/download-resource?id=${bodyData.id}&type=${bodyData.type}`);
  }

  getRecentlyProductList(requestParamModel: RequestParamModel) {
    return this.baseService.get(ApiConstants.PRODUCT_RECENTLY_VIEWED_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const productList = new PaginationModel<ProductModel>();
        const headers = response.headers;
        productList.setHeaders(headers);
        productList.data = response.body.data as ProductModel[];
        return productList;
      }, (err: any) => {
        return err;
      }));
  }

  // download product sizing table
  downloadProductSizingTable(productID: number): any {
    return this.baseService.get(ApiConstants.PRODUCT_DOWNLOAD_FORMAT_SIZE_TABLE_URL + productID)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  // download price list table
  downloadPriceListTable(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.PRODUCT_DOWNLOAD_PRICE_TABLE_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        return response.body.data;
      }, (err: any) => {
        return err;
      }));
  }

  // get single user details.
  getProductDetails(accountId: number, requestParamModel: RequestParamModel): Observable<ProductModel> {
    if (accountId !== 0) {
      return this.baseService.get(ApiConstants.PRODUCT_DETAIL_URL + accountId, requestParamModel)
        .pipe(map<any, ProductModel>(response => {
          return response.body.data as ProductModel;
        }, (err: any) => {
          return err;
        }));
    } else {
      return this.baseService.get(ApiConstants.PRODUCT_LIST_URL, requestParamModel)
        .pipe(map<any, ProductModel>(response => {
          return response.body.data[0] as ProductModel;
        }, (err: any) => {
          return err;
        }));
    }
  }

  getConsumableCalculators(model: any) {
    // save user if new then add if old then update
    return this.baseService.post(ApiConstants.PRODUCT_CONSUMABLE_CALCULATION_URL, model)
      .pipe(map<any, number>(response => {
          return response.data;
        }, (err: any) => {
          return err;
        })
      );
  }
}
