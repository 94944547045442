import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, provideImageKitLoader,NgOptimizedImage} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BaseInterceptor} from '@coreinterceptor';
import {ThemeOptions} from './theme-options';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {ConfirmModalService} from './components/confirm-modal/confirm-modal.service';
import {AuthGuardService} from '@coreguards';
import {NumberDirective} from './utils/numbers-only.directive';
import {PaginationComponent} from './components/pagination/pagination.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FaqArticleComponent} from './components/faq-article/faq-article.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {LookingForMorePopupComponent} from './components/looking-for-more-popup/looking-for-more-popup.component';
import {DecimalsInputDirective} from '@coreutils';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {RouterModule} from '@angular/router';
import {ProductImageSliderModalComponent} from './components/product-image-slider-modal/product-image-slider-modal.component';
import {SwiperModule} from "swiper/angular";
import {ProductFavouriteSuccessModalComponent} from './components/product-favourite-success-modal/product-favourite-success-modal.component';
import { environment } from 'src/environments/environment';

export const interceptorProviders =
  [
    {provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true},
  ];


@NgModule({
  declarations: [
    ConfirmModalComponent,
    NumberDirective,
    DecimalsInputDirective,
    PaginationComponent,
    FaqArticleComponent,
    NotFoundComponent,
    LookingForMorePopupComponent,
    ProductImageSliderModalComponent,
    ProductFavouriteSuccessModalComponent,
  ],

  imports: [
    FormsModule,
    NgbModule,
    PaginationModule,
    CommonModule,
    ModalModule.forRoot(),
    NgSelectModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    RouterModule,
    SwiperModule,
    NgOptimizedImage
  ],

  exports: [
    NgbModule,
    PaginationComponent,
    PaginationModule,
    ConfirmModalComponent,
    ModalModule,
    FaqArticleComponent,
    NgSelectModule,
    LookingForMorePopupComponent,
    NumberDirective,
    HttpClientModule,
    DecimalsInputDirective,
    AutocompleteLibModule,
    NotFoundComponent,
    ProductImageSliderModalComponent,
    ProductFavouriteSuccessModalComponent,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  providers: [
    ThemeOptions,
    ConfirmModalService,
    AuthGuardService,
    interceptorProviders,
    provideImageKitLoader(environment.imageKitEndpoint)
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
