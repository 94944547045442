<div class="samplep-order-confirmation">
  <div class="modal-body">
    <button type="button" aria-label="Close" class="btn-close close pull-right clsoe-btn" data-dismiss="modal"
            aria-label="Close" (click)="ngbActiveModal.close()"><span aria-hidden="true"
                                                                      class="visually-hidden">×</span></button>
    <div class="main-description">
      <p id="textHtml" class="description-label">Looking for Decor?</p>
      <p class="description">We've recently made the tough decision to cease offering decor as part of our greater
        collection. This being the case, all remaining planters have been significantly discounted. You can view what's
        available via the button below.</p>
      <a class="accept-btn btn"
         href="{{ s3BucketUrl + '/WEBSITE_ASSETS/GatherCo_DecorClearancePricelist_MarbleMetal_Q1_2024_V3.pdf' }}"
         target="_blank">View Decor</a>
    </div>
  </div>
</div>
