<div class="filter-family-section loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
    <div class="filter-family-inner">
        <div class="menu-sub-options">
            <ul>
                <li>
                    <div class="product-item-list">
                        <div class="product-item-list-inner"
                            (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_SALE, +Constants.PRICE_RANGE_LEVEL_SALE_ID, Constants.PRICE_RANGE_LEVEL_9);">
                            <img src="{{Constants.S3_BUCKET_URL}}/sale-makermark.svg" width="30" height="30"
                                (error)="helperService.getErrorImage($event)" alt="Sale Maker Mark" />
                            <label>Sale <span class="qty-no"></span></label>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="product-item-list">
                        <div class="product-item-list-inner"
                            (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_LOW_LEAD_TIME, +Constants.PRODUCT_FILTER_LOW_LEAD_TIME_ID, Constants.PRODUCT_FILTER_LOW_LEAD_TIME_VALUE);">
                            <img src="{{Constants.S3_BUCKET_URL}}/low-lead-time.svg" width="30" height="30"
                                alt="Low Lead Time">
                            <label>Low lead Time <span class="qty-no"></span></label>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="product-item-list">
                        <div class="product-item-list-inner"
                            (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_NEW_PRODUCTS, +Constants.PRODUCT_FILTER_NEW_PRODUCT_ID, Constants.PRODUCT_FILTER_NEW_PRODUCT_VALUE);">
                            <img src="{{Constants.S3_BUCKET_URL}}/filter-new-icon.svg" width="30" height="30"
                                alt="Filter New Product">
                            <label>New Products <span class="qty-no"></span></label>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="filter-family-product">
            <div class="product-items">
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.NEW_ARRIVAL_PRODUCT_LINK}}">
                            <img src="{{Constants.NEW_ARRIVAL_IMG_LINK}}" width="389" height="513"
                                alt="Product Image">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>New</h3>
                                        <span>ARRIVAL</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.FEATURED_SURFACE_PRODUCT_LINK_1}}">
                            <img src="{{Constants.FEATURED_SURFACE_IMG_LINK_1}}" width="389" height="513"
                                alt="Product Image">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>Featured</h3>
                                        <span>SURFACE</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.FEATURED_SURFACE_PRODUCT_LINK_2}}">
                            <img src="{{Constants.FEATURED_SURFACE_IMG_LINK_2}}" width="389" height="513"
                                alt="Product Image">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>Featured</h3>
                                        <span>SURFACE</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
