import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {HelperService} from "@coreservices";

@Component({
    selector: 'app-more-filter',
    templateUrl: './more-filter.component.html',
    styleUrls: ['./more-filter.component.css', '../mega-menu.component.css']
})
export class MoreFilterComponent implements OnInit {
    Constants = Constants;
    @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();
    isLoading = false;
    s3BucketImageURL!: any;

    constructor(
        private router: Router,
        public helperService: HelperService,
        public changeDetection: ChangeDetectorRef,
    ) {
    }

    /************************************
     * Life cycle hooks start
     ************************************/
    ngOnInit(): void {
        this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    }
    /************************************
     * Life cycle hooks end
     ************************************/

    /************************************
     * Redirection functions start
     ************************************/
    redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
        this.redirectFromChildMegaMenu.emit({searchType: searchType, searchId: searchId, searchName: searchName});
    }
    /************************************
     * Redirection functions end
     ************************************/
}
