import { Injectable } from '@angular/core';

@Injectable()
export class ThemeOptions {

  // Profile picture
  profilePicture = '';
  // Company Picture
  companyPicture = ''

  // UserLogin
  isUserAuthenticated = false;

  // Right Sidebar
  resetPasswordForm = false;
  registrationForm = 'trade';
  rightSidebarAccountLoader = false;
  rightSidebar = false;
  rightSidebarSurface = false;
  rightSidebarDecore = false;
  leftSidebarSurface = false;
  rightSidebarQuote = false;
  rightSidebarSampleOrder = false;
  rightSidebarPriceListQuote = false;
  formatAndSizeSelection = false;
  verificationSidebar = false;
  megamenuIsOpen = false;
}
