<div class="filter-format-section loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
    <div class="filter-format-inner">
        <ul>
            <li *ngFor="let format of formats">
                <div class="filter-format-item"
                    (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_FORMAT, format.id, format.name);">
                    <div class="format-image">
                        <img class=""
                            src="{{ format && format.hero_format_product_format_icon ? s3BucketProductUrl + '/' + format.hero_format_product_format_icon + IMAGE_SIZE_58_X_58 : '' }}"
                            (error)="helperService.getErrorImage($event)" alt="{{getFileName(format.icon)}}">
                    </div>
                    <div class="format-name">
                        <label>{{ format.name }}</label>
                        <span class="qty-no"></span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>