import {Injectable} from '@angular/core';
import {BaseService} from "@coreutils";
import {MaterialModel, PaginationModel, RequestParamModel} from "@coremodels";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  constructor(private baseService: BaseService) {
  }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.MATERIAL_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<MaterialModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as MaterialModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

  // get details.
  getDetails(materialID: number, requestParamModel?: RequestParamModel): Observable<MaterialModel> {
    return this.baseService.get(ApiConstants.MATERIAL_DETAIL_URL + materialID, requestParamModel)
      .pipe(map<any, MaterialModel>(response => {
        return response.body.data as MaterialModel;
      }, (err: any) => {
        return err;
      }));
  }

  // get all user list
  getMaterial(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.MATERIAL_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<MaterialModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as MaterialModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

}
