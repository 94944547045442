<div class="filter-family-section loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
    <div class="filter-content-top">
        <ul>
            <ng-container *ngFor="let family of families.slice(0, 26); trackBy:trackByFamilyName">
                <li *ngIf="family.children && family.children.length > 0">
                    <span class="family-name" [ngClass]="selectedFamily == family.title ? 'active' : '' "
                        (click)="toggleFamilyList(family.title);$event.stopImmediatePropagation();">
                        {{ family.title}}</span>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="filter-family-inner">
        <div class="menu-sub-options">
            <ul>
                <li *ngFor="let child of familyList.children; trackBy:trackByFamilies">
                    <div class="product-item-list">
                        <div class="product-item-list-inner"
                            (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_FAMILY, child.id, child.name);$event.stopImmediatePropagation();">
                            <img src="{{ s3BucketProductUrl + '/' + child.hero_format_image + IMAGE_SIZE_125_X_125 }}"
                                (error)="helperService.getErrorImage($event)"
                                alt="{{getFileName(child.hero_format_image)}}" />
                            <label>{{ child.name | titlecase}} <span class="qty-no"></span></label>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="filter-family-product">
            <div class="product-items">
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.NEW_ARRIVAL_PRODUCT_LINK}}">
                            <img src="{{Constants. NEW_ARRIVAL_IMG_LINK}}" width="389px" height="513px"
                                alt="Product Image" (error)="helperService.getErrorImage($event)">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>New</h3>
                                        <span>ARRIVAL</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.FEATURED_SURFACE_PRODUCT_LINK_1}}">
                            <img src="{{Constants.FEATURED_SURFACE_IMG_LINK_1}}" width="389px" height="513px"
                                alt="Product Image" (error)="helperService.getErrorImage($event)">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>Featured</h3>
                                        <span>SURFACE</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="product-item">
                    <div class="product-image">
                        <a href="{{Constants.FEATURED_SURFACE_PRODUCT_LINK_2}}">
                            <img src="{{Constants.FEATURED_SURFACE_IMG_LINK_2}}" width="389px" height="513px"
                                alt="Product Image" (error)="helperService.getErrorImage($event)">
                            <div class="product-description">
                                <div class="product-description-inner">
                                    <div class="productdescription">
                                        <h3>Featured</h3>
                                        <span>SURFACE</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>