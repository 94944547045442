<div class="filter-colour-section loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
    <div class="filter-colour-inner">
        <ul>
            <li *ngFor="let color of colors">
                <div class="filter-colour-item"
                    (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_COLOR, color.id, color.name);">
                    <div class="filter-colour-image">
                        <span [style.background-color]="color.code"></span>
                    </div>
                    <div class="filter-colour-name">
                        <label>{{ color.name }}</label>
                        <span class="qty-no"></span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>