import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationModel, RequestParamModel, SettingModel } from '@coremodels';
import { AlertService, AuthService, FamilyService, HelperService } from '@coreservices';
import { CommonConstants as Constants } from 'src/app/core/constants/common-constants';

@Component({
    selector: 'app-filter-by-family',
    templateUrl: './filter-by-family.component.html',
    styleUrls: ['./filter-by-family.component.css', '../mega-menu.component.css']
})
export class FilterByFamilyComponent implements OnInit {
    families?: any = [];
    familyList: any = [];
    selectedFamily: string = 'A';
    Constants = Constants;
    s3BucketProductUrl!: any;
    IMAGE_SIZE_125_X_125 = Constants.IMAGE_SIZE_125_X_125;
    @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();
    isLoading = false;
    s3BucketImageURL!: any;

    constructor(
        private router: Router,
        private familyService: FamilyService,
        public helperService: HelperService,
        public changeDetection: ChangeDetectorRef,
        public authService: AuthService,
        public alertService: AlertService
    ) {
    }

    /************************************
     * Life cycle hooks start
     ************************************/
    async ngOnInit() {
        this.s3BucketProductUrl = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
        this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
        this.getFamilies();
    }

    /************************************
     * Life cycle hooks end
     ************************************/

    /************************************
     * Redirection functions start
     ************************************/
    redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
        this.redirectFromChildMegaMenu.emit({ searchType: searchType, searchId: searchId, searchName: searchName });
    }

    /************************************
     * Redirection functions end
     ************************************/

    /************************************
     * Other functions start
     ************************************/
    getFamilies() {
        this.isLoading = true;
        let requestParamModel = new RequestParamModel();
        requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
        requestParamModel.fields = ['id', 'name', 'code', 'hero_format_image'];
        requestParamModel.addDefaultFilter('status', Constants.STATUS_ACTIVE, 'number');
        requestParamModel.sortChange('name', 'asc');
        this.familyService.getFamily(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
            requestParamModel.update<any>(dataList);
            const families = dataList.data;
            const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
            alphabet.forEach((alpha) => {
                let tmpObject: any = { title: alpha, children: [], expand: false };
                families.forEach((family) => {
                    if (family.name.charAt(0).toUpperCase() == alpha.toUpperCase()) {
                        tmpObject.children.push(family);
                    }
                });
                this.families.push(tmpObject);
                this.toggleFamilyList(this.selectedFamily);
                this.isLoading = false;
            });

            this.changeDetection.detectChanges();
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
        });
    }

    toggleFamilyList(alphabet: string) {
        if (alphabet) {
            this.familyList = this.families.find((x: any) => x.title == alphabet);
            this.selectedFamily = alphabet;
        }
    }

    trackByFamilies(index: any, item: any) {
        return item.name;
    }

    trackByFamilyName(index: any, item: any) {
        return item.name;
    }

    getFileName(str: any) {
        var fileName = '';
        if (str != undefined && str !== null && str !== '') {
            var newFileName = str.split('\\').pop().split('/').pop(); // Get file name
            newFileName = newFileName.split("/").slice(-1).join().split(".").shift(); // Remove extension
            fileName = newFileName.replace(/_/g, ' '); // Replace underscore(_) with dash(-)
        }
        return fileName;
    }

    /************************************
     * Other functions end
     ************************************/

}


