import {Injectable} from '@angular/core';
import {BaseService} from "@coreutils";
import {FormatModel, PaginationModel, RequestParamModel} from "@coremodels";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ApiConstants} from "../constants/api-constants";

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor(private baseService: BaseService) {
  }

  // Get Lists
  getLists(requestParamModel: RequestParamModel): any {
    return this.baseService.getWithoutToken(ApiConstants.FORMAT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const responseList = new PaginationModel<FormatModel>();
        const headers = response.headers;
        responseList.setHeaders(headers);
        responseList.data = response.body.data as FormatModel[];
        return responseList;
      }, (err: any) => {
        return err;
      }));
  }

  // get details.
  getDetails(formatID: number, requestParamModel?: RequestParamModel): Observable<FormatModel> {
    return this.baseService.get(ApiConstants.FORMAT_DETAIL_URL + formatID, requestParamModel)
      .pipe(map<any, FormatModel>(response => {
        return response.body.data as FormatModel;
      }, (err: any) => {
        return err;
      }));
  }

  // get all user list
  getFormat(requestParamModel: RequestParamModel): any {
    return this.baseService.get(ApiConstants.FORMAT_LIST_URL, requestParamModel)
      .pipe(map<any, any>(response => {
        const accountList = new PaginationModel<FormatModel>();
        const headers = response.headers;
        accountList.setHeaders(headers);
        accountList.data = response.body.data as FormatModel[];
        return accountList;
      }, (err: any) => {
        return err;
      }));
  }

}
