import { Component, OnInit } from '@angular/core';
import { AlertService, AuthService } from '@coreservices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {CommonConstants as Constants} from "src/app/core/constants/common-constants";

@Component({
  selector: 'app-decor-note',
  templateUrl: './decor-note.component.html',
  styleUrls: ['./decor-note.component.css']
})
export class DecorNoteComponent implements OnInit {

  s3BucketUrl!: any;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private authService: AuthService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.s3BucketUrl = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
  }

}
