<footer>
  <div class="signup-section" *ngIf="!isUserAuthenticated" [style.background-image]="getBackgroundImageUrl()">
    <div class="signup-inner-container">
      <div class="sign-up-title">Sign up with <br>Gather Co</div>
      <p>Making an account is easy, missing out is hard.
        <br/>Gain access to our industry leading platform by signing up now.
      </p>
      <div class="signup-buttons">
        <button class="btn white-bordered-btn  trade-sign-in  trade_general_user" data-name="trade"
                (click)="rightSidebarToggle('trade');">
          Get Started
        </button>
      </div>
    </div>
  </div>
  <div class="none-fixed-content">
    <div class="explore surface-decore-section">
      <div class="row align-items-center">
        <a class="col-lg-6 col-md-6 col-sm-6 col-12 surface-and-decore-hover surface-explore"
             [routerLink]="['surfaces']">
          <div class="explore-box">
            <span>Explore</span>
            <h3>Surfaces</h3>
          </div>
        </a>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 surface-and-decore-hover decore-explore"
             (click)="onDecorNoteOpen()">
          <div class="explore-box">
            <span>Explore</span>
            <h3>Decor</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-top">
    <div class="footer-top-container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="footer-links">
            <ul>
              <li>
                <span class="footer-links-inner" [routerLink]="['contact-us']">
                  <span>Contact</span>
                </span>
              </li>
              <li>
                <span class="footer-links-inner" [routerLink]="['resource']">
                  <span>Resource Hub</span>
                </span>
              </li>
              <li *ngIf="!isUserAuthenticated">
                <span (click)="myaccountAction()" class="signin-trigger footer-links-inner">
                  <span>My Account</span>
                </span>
              </li>
              <li *ngIf="isUserAuthenticated">
                <span class="footer-links-inner" [routerLink]="['account']">
                  <span>My Account</span>
                </span>
              </li>
              <li>
                <span class="footer-links-inner" [routerLink]="['help-center']">
                  <span>Help Center</span>
                </span>
              </li>
              <li>
                <span class="footer-links-inner" [routerLink]="['term-of-service']">
                  <span>T&C’s</span>
                </span>
              </li>
              <li>
                <span class="footer-links-inner" [routerLink]="['gather-commitment']">
                  <span>The Gather Commitment</span>
                </span>
              </li>
              <li>
                <span class="footer-links-inner" [routerLink]="['samples']">
                  <span>Samples</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="gather-footer-logo">
            <img [ngSrc]="'/WEBSITE_ASSET_STAGING/logo-white.svg'" width="172" height="52"
                 ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                 alt="GatherCo" title="GatherCo"/>
            <p>
              <a href="https://www.instagram.com/gathercoaustralia/" aria-label="GATHERCOAUSTRALIA" class="insta-link"
                 target="_blank">@GATHERCOAUSTRALIA</a>
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="footer-social-medial">
            <span>Join us on social media</span>
            <ul>
              <li>
                <a href="https://www.instagram.com/gathercoaustralia/" aria-label="Instagram" target="_blank">
                  <span class="icon-instagram"></span>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/gathercoaustralia/" aria-label="Facebook" target="_blank">
                  <span class="icon-facebook"></span>
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com.au/gathercoaus/" aria-label="Pinterest" target="_blank">
                  <span class="icon-pinterest"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="row align-items-center">
      <div class="col-xl-4 col-ld-4 col-md-5 col-sm-12 col-12">
        <div class="powered-by">
          <ul>
            <li>
              <div class="powered-by-logo">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/pay-advantage.svg'" width="91" height="18"
                     ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                     alt="Pay Advantage" title="Pay Advantage"/>
              </div>
            </li>
            <li>
              <div class="powered-by-logo">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/BPAY.png'" width="28" height="18"
                     ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                     alt="BPAY" title="BPAY"/>
              </div>
            </li>
            <li>
              <div class="powered-by-logo">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/Figurehead.svg'" width="48" height="18"
                     ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                     alt="Visa" title="Visa"/>
              </div>
            </li>
            <li>
              <div class="powered-by-logo">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/master.svg'" width="27" height="18"
                     ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                     alt="Master" title="Master"/>
              </div>
            </li>
            <li>
              <div class="powered-by-logo">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/american-express.svg'" width="41" height="18"
                     ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                     alt="American Express" title="American Express"/>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xl-4 col-ld-4 col-md-3 col-sm-12 col-12">
        <div class="smile-logo">
          <a [routerLink]="['/tiles-for-smiles']">
            <img [ngSrc]="'/WEBSITE_ASSET_STAGING/smiles-logo.svg'" width="105" height="34"
                 ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                 alt="Smiles" title="Smiles"/>
            <p>Goods for the Greater Good.</p>
          </a>
        </div>
      </div>
      <div class="col-xl-4 col-ld-4 col-md-4 col-sm-12 col-12">
        <div class="copyright">
          <a href="{{constants.ARCHIPRO_LINK}}" target="_blank" rel="nofollow, noindex">
            <img [ngSrc]="'/WEBSITE_ASSET_STAGING/archipro-logo.svg'" width="91" height="33"
                 ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"
                 alt="Archipro Logo" title="Archipro Logo"/>
          </a>
          <p>
            © {{ currentYear }} Gather Co. All rights reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
