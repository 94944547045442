import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RequestParamModel, PaginationModel, FormatModel, SettingModel} from '@coremodels';
import {AlertService, AuthService, FormatsService, HelperService} from '@coreservices';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';

@Component({
    selector: 'app-filter-by-format',
    templateUrl: './filter-by-format.component.html',
    styleUrls: ['./filter-by-format.component.css','../mega-menu.component.css']
})
export class FilterByFormatComponent implements OnInit {
    formats!: FormatModel[];
    Constants = Constants;
    s3BucketProductUrl!: any;
    IMAGE_SIZE_58_X_58 = Constants.IMAGE_SIZE_58_X_58;
    @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();
    isLoading = false;

    constructor(
        private router: Router,
        private formatService: FormatsService,
        private helperService: HelperService,
        public changeDetection: ChangeDetectorRef,
        public authService: AuthService,
        public alertService: AlertService
    ) {
    }

    /************************************
     * Life cycle hooks start
     ************************************/
    async ngOnInit() {
        this.s3BucketProductUrl = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
        this.getFormats();
    }

    /************************************
     * Life cycle hooks end
     ************************************/

    /************************************
     * Redirection functions start
     ************************************/
    redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
        this.redirectFromChildMegaMenu.emit({searchType: searchType, searchId: searchId, searchName: searchName});
    }

    /************************************
     * Redirection functions end
     ************************************/

    /************************************
     * Other functions start
     ************************************/
    getFormats() {
        this.isLoading = true;
        let requestParamModel = new RequestParamModel();
        requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
        requestParamModel.fields = ['id', 'name', 'hero_format_product_format_icon'];
        requestParamModel.addDefaultFilter('type', Constants.PRODUCT_TYPE_SURFACE, 'number');
        requestParamModel.addDefaultFilter('status', Constants.STATUS_ACTIVE, 'number');
        requestParamModel.sortChange('name', 'asc')
        this.formatService.getLists(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
            requestParamModel.update<any>(dataList);
            this.formats = dataList.data;
            this.isLoading = false;
            this.changeDetection.detectChanges();
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
        });
    }

    getFileName(str: any) {
        var fileName = '';
        if (str != undefined && str !== null && str !== '') {
            var newFileName = str.split('\\').pop().split('/').pop(); // Get file name
            newFileName = newFileName.split("/").slice(-1).join().split(".").shift(); // Remove extension
            fileName = newFileName.replace(/_/g, ' '); // Replace underscore(_) with dash(-)
        }
        return fileName;
    }

    /************************************
     * Other functions end
     ************************************/
}



