import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {CoreModule} from "./core/core.module";
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {NgbAccordionModule, NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MegaMenuComponent} from './layout/mega-menu/mega-menu.component';
import {RightSidebarComponent} from './layout/right-sidebar/right-sidebar.component';
import {LoginComponent} from './auth/login/login.component';
import {RegisterComponent} from './auth/register/register.component';
import {ToastrModule} from 'ngx-toastr';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {FilterByFamilyComponent} from "./layout/mega-menu/filter-by-family/filter-by-family.component";
import {FilterByFormatComponent} from "./layout/mega-menu/filter-by-format/filter-by-format.component";
import {FilterByColourComponent} from "./layout/mega-menu/filter-by-colour/filter-by-colour.component";
import {FilterByMaterialComponent} from "./layout/mega-menu/filter-by-material/filter-by-material.component";
import {MoreFilterComponent} from "./layout/mega-menu/more-filter/more-filter.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {VerifyAccountComponent} from './auth/verify-account/verify-account.component';
import {IMaskModule} from "angular-imask";
import {AlertService} from "@coreservices";
import {TabsModule} from "ngx-bootstrap/tabs";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {HttpClientModule} from "@angular/common/http";
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NgxOtpInputModule} from "ngx-otp-input";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import {environment} from "../environments/environment";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RouterModule} from "@angular/router";
import {SharedModule} from "./shared/shared.module";
import {DecorNoteComponent} from "./layout/footer/modal/decor-note/decor-note.component";
import {CarrierJobThankYouModalComponent} from './home/modal/carrier-job-thank-you-modal/carrier-job-thank-you-modal.component';
import {CarrierJobDeclineModalComponent} from './home/modal/carrier-job-decline-modal/carrier-job-decline-modal.component';
import {NgOptimizedImage, provideImageKitLoader} from "@angular/common";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MegaMenuComponent,
    FilterByFamilyComponent,
    FilterByFormatComponent,
    FilterByColourComponent,
    FilterByMaterialComponent,
    MoreFilterComponent,
    RightSidebarComponent,
    LoginComponent,
    RegisterComponent,
    VerifyAccountComponent,
    DecorNoteComponent,
    CarrierJobThankYouModalComponent,
    CarrierJobDeclineModalComponent,
  ],

  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgOptimizedImage,
    ToastrModule.forRoot(
      {maxOpened: 1, autoDismiss: true, preventDuplicates: true,}
    ),
    BsDropdownModule,
    NgbDropdownModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    IMaskModule,
    GooglePlaceModule,
    TooltipModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot({}),
    NgxOtpInputModule,
    SocialLoginModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    AlertService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleAppId
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    Meta,
    provideImageKitLoader(environment.imageKitEndpoint)
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
}
