import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {
  ChangeDetectorRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {
  UserModel
} from '@coremodels';
import {
  AccountsService,
  AlertService,
  AuthService,
  ColorService, CommunicationService,
  FamilyService,
  FormatsService, HelperService,
  MaterialsService,
  SubscribersService
} from '@coreservices';
import {BaseService} from '@coreutils';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';

import {ThemeOptions} from 'src/app/core/theme-options';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MegaMenuComponent} from "../mega-menu/mega-menu.component";
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  //#region Declare variable
  public isLoading: boolean = false;
  public currentRoute: string = '';
  public darkHeader: boolean = false;
  public isUserAuthenticated: boolean = false;
  public urlArray: any;
  public userData: UserModel = new UserModel();
  public isCollapsed = true;
  quotesCount: number = 0;
  gathercoCreditCount: number = 0
  orderCount: number = 0;
  public Constants = Constants;
  sampleOrderCount?: number = 0;
  isTradeUser: boolean = false;
  isBrowser: any;
  isServer: any;
  isPageDetailLoad = true;
  //#endregion
  isMegaMenuOpen: boolean = false;
  isMobileResolution!: boolean;
  s3BucketImageURL!: any;
  userProfile!: UserModel;
  gatherCoCredit = 0.0;
  profileUpdated!: any;
  navbarAnimation!: boolean;
  isMegamenuOpenFlag = false;

  // Create a subject for hover events
  private hover$ = new Subject<boolean>();
  private destroyed$ = new Subject<void>();
  private hoverSubscription!: Subscription;

  @HostListener('window:resize', ['$event']) onResize($event: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 1199) {
        this.navbarAnimation = true;
        this.isMobileResolution = true;
        this.navbarAnimation = true;
        this.modalService1.dismissAll();
      } else {
        this.isMobileResolution = false;
        this.navbarAnimation = false;
      }
      this.isCollapsed = true;
    }
  }

  public isShowMegaMenu = false;
  isHovered: boolean = true;

  constructor(
    private router: Router,
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private authService: AuthService,
    private materialService: MaterialsService,
    private formatService: FormatsService,
    private colorService: ColorService,
    private familyService: FamilyService,
    private changeDetectionRef: ChangeDetectorRef,
    private baseService: BaseService,
    @Inject(PLATFORM_ID) private platformId: any,
    public renderer: Renderer2,
    private modalService1: NgbModal,
    private accountServices: AccountsService,
    public helperService: HelperService,
    private communicationService: CommunicationService,
    private alertService: AlertService,
  ) {
    router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
    });
    let sampleOrders = localStorage.getItem('sampleOrder');
    if (sampleOrders != null) {
      sampleOrders = JSON.parse(sampleOrders);
      this.sampleOrderCount = sampleOrders?.length;
    } else {
      this.sampleOrderCount = 0
    }
    this.isUserAuthenticated = this.authService.isUserAuthenticated()

    this.subscriberService.isUserLoggedIn.subscribe((options) => {
      this.sampleOrderCount = 0
      this.globals.isUserAuthenticated = options;
      this.isUserAuthenticated = options;
      if (this.isUserAuthenticated) {
        this.userData = this.authService.getUserProfile();
        this.isTradeUser = this.userData.role === Constants.USER_TYPE_TRADE;
        if (this.userData.id) {
          this.getCounts()
        }
      } else {
        this.userData = new UserModel();
      }
      this.changeDetectionRef.markForCheck();
    });
    this.subscriberService.sampleOrderProduct.subscribe((sampleOrder) => {
      sampleOrder = JSON.parse(sampleOrder);
      this.sampleOrderCount = sampleOrder.length;
    })
    this.subscriberService.headerCountUpdate.subscribe((options) => {
      this.getCounts()
    });
    this.subscriberService.getCountFormLocalStorage.subscribe((data) => {
      if (data) {
        this.getCounts()
      }
    })
    /*this.subscriberService.megamenu.subscribe((eventData: boolean) => {
      this.isMegamenuOpenFlag = eventData;
      this.isHovered = !eventData;
      this.changeDetectionRef.markForCheck();
    });*/
    this.subscriberService.headerCreditCountUpdate.subscribe((options) => {
      this.getCounts()
    });

    // this.isBrowser = isPlatformBrowser(platformId);
    // this.isServer = isPlatformServer(platformId);
    // if (this.isServer) {
    //   this.isPageDetailLoad = false;
    // } else {
    //   this.isPageDetailLoad = true;
    // }
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    // this.hoverSubscription = this.hover$
    //   .pipe(
    //     debounceTime(200),
    //     switchMap(hovered => {
    //       this.isHovered = !hovered;
    //       return hovered ? this.showMegaMenu() : this.hideMegaMenu();
    //     }),
    //     takeUntil(this.destroyed$)
    //   )
    //   .subscribe();

    if (window.innerWidth <= 1199) {
      this.navbarAnimation = true;
    } else {
      this.navbarAnimation = false;
    }

    this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/WEBSITE_ASSET_STAGING';
    this.userData = this.authService.getUserProfile();
    this.isUserAuthenticated = this.authService.isUserAuthenticated();
    if (this.isUserAuthenticated) {
      this.getCounts()
    }

    const userModal = this.authService.getUserProfile();
    if (userModal) {
      this.isTradeUser = userModal.role === Constants.USER_TYPE_TRADE;
    }

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scrollEvent, true);
    }
    this.subscriberService.quoteOrderCount.subscribe((data) => {
      if (data) {
        this.getCounts()
      }
    })
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.scrollEvent, true);
    }
    // this.hoverSubscription.unsubscribe();
    // this.destroyed$.next();
    // this.destroyed$.complete();
  }

  /************************************
   * Life cycle hooks end
   ************************************/


  /************************************
   * Redirection functions start
   ************************************/
  redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
    localStorage.removeItem('search_keyword');
    localStorage.setItem("search_keyword", searchName);

    if (searchType == Constants.FILTER_PRODUCT_WITH_SALE || searchType == Constants.FILTER_PRODUCT_WITH_LOW_LEAD_TIME || searchType == Constants.FILTER_PRODUCT_WITH_NEW_PRODUCTS) {
      // Store search_id into local storage
      localStorage.removeItem('search_id');
      localStorage.setItem("search_id", JSON.stringify(searchId));

      this.router.navigate(['/surfaces/search/' + searchType]);
    } else {
      this.router.navigate(['/surfaces/search/' + searchType + '/' + searchId]);
    }
  }

  /************************************
   * Redirection functions end
   ************************************/


  /************************************
   * Other functions start
   ************************************/
  scrollEvent = (event: any): void => {
    let scrollPosition = 0;
    if (event.srcElement.scrollingElement) {
      scrollPosition = event.srcElement.scrollingElement.scrollTop;
    }
    if (event.target['scrollingElement'] != undefined) {
      if (scrollPosition >= 80) {

        this.urlArray = this.router.url.split('/');
        if (this.urlArray.length > 0 && typeof this.urlArray[1] != undefined && this.urlArray[1] === 'term-of-service') {
          this.darkHeader = false;
          var termsService = document.getElementById('t&c')
          termsService?.classList.add('term-condition')
        } else {
          this.darkHeader = true;
        }
      } else {
        this.darkHeader = false;
        var termsService = document.getElementById('t&c')
        termsService?.classList.remove('term-condition')
      }
    }
  }

  rightSidebarToggle() {
    this.globals.registrationForm = '';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  logout() {
    this.authService.clearToken();
    this.isUserAuthenticated = false;
    this.globals.isUserAuthenticated = false;
    this.subscriberService.userLoggedInToggle(this.globals.isUserAuthenticated)
    this.router.navigate(['/']);
  }

  headerWithoutBannerFn() {
    this.urlArray = this.router.url.split('/');
    if (this.urlArray.length > 0 && typeof this.urlArray[1] != undefined) {
      const headerWithoutBannerAllowURL = ['', 'login', 'reset-password', 'gather-commitment', 'samples', 'price-list', 'resource', 'contact-us', 'project', 'blog', 'our-story', 'tiles-for-smiles', 'help-center', 'term-of-service'];
      if (this.urlArray[1] === 'project' && this.urlArray.length >= 3) {
        return true;
      } else if (this.urlArray[1] === 'blog' && this.urlArray.length == 3) {
        return true;
      } else if (!headerWithoutBannerAllowURL.includes(this.urlArray[1])) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isWhiteIcon() {
    this.urlArray = this.router.url.split('/');
    if (this.urlArray.length > 0 && typeof this.urlArray[1] != undefined) {
      const headerWithoutBannerAllowURL = ['price-list', 'tiles-for-smiles', 'samples', 'gather-commitment', 'blog', 'help-center'];
      return headerWithoutBannerAllowURL.includes(this.urlArray[1]);
    }
    return false;
  }

  rightSidebarToggleSignIn() {
    this.globals.registrationForm = '';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  rightSidebarToggleSignUp() {
    this.globals.registrationForm = 'trade';
    setTimeout(() => {
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    }, 500)
    if (this.globals.rightSidebar == true) {
      this.communicationService.emitRightSidebarClick(false);
      this.globals.rightSidebar = false;
    } else {
      this.communicationService.emitRightSidebarClick(true);
      this.globals.rightSidebar = true;
    }
  }

  isActiveMenu(url: string) {
    const routeArray = this.router.url.split("/");
    if (routeArray && routeArray[1] === url) {
      return 'active';
    }
    return '';
  }

  openSampleOrderSidebar() {
    setTimeout(() => {
      this.subscriberService.rightDrawerSampleOrderToggle(true);
    }, 500)
    this.communicationService.emitSampleOrderRightSidebarClick(true);
  }

  getCounts() {
    this.orderCount = localStorage.getItem('orderTotalCount') ? (+localStorage.getItem('orderTotalCount')!) : 0;
    this.quotesCount = localStorage.getItem('quotesActiveCount') ? (+localStorage.getItem('quotesActiveCount')!) : 0;
    this.gathercoCreditCount = localStorage.getItem('gathercoCredit') ? (+localStorage.getItem('gathercoCredit')!) : 0;
    this.changeDetectionRef.detectChanges()
  }

  onMouseEnterOpenMegaMenu() {
    if (!this.isMegaMenuOpen) {
      this.isMegaMenuOpen = true;
      const instance = this.modalService1.open(MegaMenuComponent, {
        animation: true,
        size: 'xl',
        windowClass: 'full-size-modal mega-menu-modal'
      });
      instance.closed.subscribe((result) => {
        this.isMegaMenuOpen = !this.isMegaMenuOpen;
      });

      instance.dismissed.subscribe((result: any) => {
        this.isMegaMenuOpen = !this.isMegaMenuOpen;
      })
    }
  }

  onMouseEnterCloseMegaMenu() {
    if (this.isMegaMenuOpen) {
      this.modalService1.dismissAll();
    }
  }

  onMouseEnterMenu() {
    this.isShowMegaMenu = true;
    setTimeout(() => {
      this.isHovered = false;
    }, 200);
  }

  onMouseLeaveMenu() {
    setTimeout(() => {
      this.isHovered = true;
    }, 400);

    setTimeout(() => {
      this.isShowMegaMenu = false;
    }, 800);
  }

  openMegamenu() {
    setTimeout(() => {
      this.isMegamenuOpenFlag = true;
    }, 200);
    setTimeout(() => {
      this.isHovered = false;
    }, 400);
    /*this.globals.megamenuIsOpen = true;
    setTimeout(() => {
      this.subscriberService.megamenuToggle(this.globals.megamenuIsOpen);
    }, 300);*/
  }
  unAuthenticatePriceListErrorMessage()
  {
    this.alertService.error(Constants.PREVENT_ACCESS_PRICE_LIST_ERROR_MESSAGE);
  }

  closeMegaMenu() {
    setTimeout(() => {
      this.isHovered = true;
      setTimeout(() => {
        this.isShowMegaMenu = false;
      }, 300);
    }, 200);
    /*this.globals.megamenuIsOpen = false;*/
  }

  // onMouseEnterMenu() {
  //   this.hover$.next(true);
  // }
  //
  // onMouseLeaveMenu() {
  //   this.hover$.next(false);
  // }
  //
  // showMegaMenu() {
  //   this.isShowMegaMenu = true;
  //   return new Subject().pipe(debounceTime(200));
  // }
  //
  // hideMegaMenu() {
  //   return new Subject().pipe(debounceTime(200), switchMap(() => {
  //     this.isShowMegaMenu = false;
  //     return new Subject();
  //   }));
  // }

  /************************************
   * Other functions end
   ************************************/
}
