import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MegaMenuComponent implements OnInit {
  /* ################### Variable Declaration Start ################### */
  megaMenuActiveTab = 'filter-by-family';
  @Output("redirectFromMegaMenu") redirectFromMegaMenu: EventEmitter<any> = new EventEmitter();
  /* ################### Variable Declaration End ################### */

  constructor() {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Redirection functions start
   ************************************/
  redirectFromChildMegaMenu(searchType: string, searchId: number, searchName: string) {
    this.redirectFromMegaMenu.emit({searchType: searchType, searchId: searchId, searchName: searchName});
  }
  /************************************
   * Redirection functions end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  changeMegaMenuTab(event: any, val: any) {
    event.preventDefault();
    event.stopPropagation();
    this.megaMenuActiveTab = val;
  }
  /************************************
   * Other functions end
   ************************************/
}
