<div class="filter-material-section loader-parent">
    <div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
    <div class="filter-material-inner">
        <ul>
            <li *ngFor="let material of materials">
                <span class="material-side-bar"
                    (click)="redirectFromMegaMenu(Constants.FILTER_PRODUCT_WITH_MATERIAL, material.id, material.name);">
                    {{ material.name }}
                    <span class="qty-no"></span>
                </span>
            </li>
        </ul>
    </div>
</div>