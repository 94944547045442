import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialModel, PaginationModel, RequestParamModel } from '@coremodels';
import { MaterialsService } from '@coreservices';
import { CommonConstants as Constants } from 'src/app/core/constants/common-constants';

@Component({
    selector: 'app-filter-by-material',
    templateUrl: './filter-by-material.component.html',
    styleUrls: ['./filter-by-material.component.css', '../mega-menu.component.css']
})
export class FilterByMaterialComponent implements OnInit {
    materials!: MaterialModel[];
    Constants = Constants;
    @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();
    isLoading = false;

    constructor(
        private router: Router,
        private materialService: MaterialsService,
        public changeDetection: ChangeDetectorRef,
    ) {
    }

    /************************************
     * Life cycle hooks start
     ************************************/
    ngOnInit(): void {
        this.getMaterials();
    }

    /************************************
     * Life cycle hooks end
     ************************************/

    /************************************
     * Redirection functions start
     ************************************/
    redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
        this.redirectFromChildMegaMenu.emit({ searchType: searchType, searchId: searchId, searchName: searchName });
    }

    /************************************
     * Redirection functions end
     ************************************/

    /************************************
     * Other functions start
     ************************************/
    getMaterials() {
        this.isLoading = true;
        let requestParamModel = new RequestParamModel();
        requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
        requestParamModel.fields = ['id', 'name'];
        requestParamModel.addDefaultFilter('type', Constants.PRODUCT_TYPE_SURFACE, 'number');
        requestParamModel.addDefaultFilter('status', Constants.STATUS_ACTIVE, 'number');
        requestParamModel.sortChange('name', 'asc')
        this.materialService.getLists(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
            requestParamModel.update<any>(dataList);
            this.materials = dataList.data;
            this.isLoading = false;

            this.changeDetection.detectChanges();
        }, (error: HttpErrorResponse) => {
            this.isLoading = false;
        });
    }

    /************************************
     * Other functions end
     ************************************/
}
