import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ColorModel, PaginationModel, RequestParamModel} from '@coremodels';
import {ColorService} from '@coreservices';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';

@Component({
  selector: 'app-filter-by-colour',
  templateUrl: './filter-by-colour.component.html',
  styleUrls: ['./filter-by-colour.component.css','../mega-menu.component.css']
})
export class FilterByColourComponent implements OnInit {
  colors!: ColorModel[];
  Constants = Constants;
  @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();
  isLoading = false;

  constructor(
    private router: Router,
    private colorService: ColorService,
    public changeDetection: ChangeDetectorRef,
  ) {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  ngOnInit(): void {
    this.getColors();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Redirection functions start
   ************************************/
  redirectFromMegaMenu(searchType: string, searchId: number, searchName: string) {
    this.redirectFromChildMegaMenu.emit({searchType: searchType, searchId: searchId, searchName: searchName});
  }

  /************************************
   * Redirection functions end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  getColors() {
    this.isLoading = true;
    let requestParamModel = new RequestParamModel();
    requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
    requestParamModel.fields = ['id', 'name', 'code'];
    requestParamModel.addDefaultFilter('status', Constants.STATUS_ACTIVE, 'number');
    this.colorService.getLists(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
      requestParamModel.update<any>(dataList);
      this.colors = dataList.data;
      this.isLoading = false;

      this.changeDetection.detectChanges();
    }, (error: HttpErrorResponse) => {
      this.isLoading = false;
    });
  }

  /************************************
   * Other functions end
   ************************************/
}
