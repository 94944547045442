<div class="quote-tabs">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link active" id="filter-by-family" data-toggle="tab"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-family'}"
         (click)="changeMegaMenuTab($event,'filter-by-family')" href="#">By Family<span
        class="qty-no"></span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link awaiting-payment" id="filter-by-format" data-toggle="tab" href="#"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-format'}"
         (click)="changeMegaMenuTab($event,'filter-by-format')">By Format<span class="qty-no"></span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link awaiting-payment" id="filter-by-colour" data-toggle="tab" href="#"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-colour'}"
         (click)="changeMegaMenuTab($event,'filter-by-colour')">By Colour<span class="qty-no"></span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link awaiting-payment" id="filter-by-material" data-toggle="tab" href="#"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-material'}"
         (click)="changeMegaMenuTab($event,'filter-by-material')">Material<span class="qty-no"></span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link awaiting-payment" id="filter-by-more" data-toggle="tab" href="#"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-more'}"
         (click)="changeMegaMenuTab($event,'filter-by-more')">More<span class="qty-no"></span></a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane" id="filter_by_family" [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-family'}">
      <div class="main-sub-title">
        <app-filter-by-family
          (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)"></app-filter-by-family>
      </div>
    </div>
    <div class="tab-pane" id="filter_by_format" [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-format'}">
      <div class="main-sub-title">
        <app-filter-by-format
          (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)"></app-filter-by-format>
      </div>
    </div>
    <div class="tab-pane" id="filter_by_colour" [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-colour'}">
      <div class="main-sub-title">
        <app-filter-by-colour
          (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)"></app-filter-by-colour>
      </div>
    </div>
    <div class="tab-pane" id="filter_by_material"
         [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-material'}">
      <div class="main-sub-title">
        <app-filter-by-material
          (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)"></app-filter-by-material>
      </div>
    </div>
    <div class="tab-pane" id="filter_by_more" [ngClass]="{ 'active':megaMenuActiveTab === 'filter-by-more'}">
      <div class="main-sub-title">
        <app-more-filter
          (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)"></app-more-filter>
      </div>
    </div>
  </div>
</div>
