<header id="t&c">
  <div class="header-main"
       [ngClass]="{'header-without-banner': headerWithoutBannerFn(), 'darkHeader': darkHeader,'unauthentication-zindex':!isUserAuthenticated}"
       ngbDropdown>
    <div class="container-fluid">
      <div class="header-item-container">
        <div class="menu-left-widget">
          <div class="logo">
            <div class="logo-innar" [routerLink]="['']">
              <img alt="GatherCo" title="GatherCo" src="{{Constants.S3_BUCKET_URL}}/logo.svg" loading="lazy" width="112" height="33">
            </div>
          </div>
          <div class="logo mobile-logo">
            <div [routerLink]="['']">
              <img alt="GatherCo" title="GatherCo" src="{{Constants.S3_BUCKET_URL}}/mobile-logo.svg" loading="lazy" width="20" height="28">
            </div>
          </div>
          <div class="header-menu">
            <nav class="navbar navbar-expand-xl">
              <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                      aria-label="Dropdown Menu" [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleNavbar">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div class="navbar-collapse collapse" id="collapsibleNavbar" [ngbCollapse]="isCollapsed"
                   [animation]="navbarAnimation">
                <div class="close-menu d-block d-md-none" (click)="isCollapsed = !isCollapsed"
                     [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleNavbar">
                  <span class="icon-close"></span>
                </div>
                <ul class="navbar-nav">
                  <li class="nav-item" [ngClass]="isActiveMenu('surfaces')" (mouseover)="onMouseEnterMenu()" (mouseleave)="closeMegaMenu()">
                    <a class="nav-link" role="link" [routerLink]="['surfaces']" [attr.aria-expanded]="isHovered">
                      <span>Showroom</span>
                    </a>
                    <ng-container *ngIf="isShowMegaMenu">
                      <div class="maga-menu-main">
                        <app-mega-menu class="mega-menu" #collapse="ngbCollapse" [(ngbCollapse)]="isHovered"
                                       (redirectFromMegaMenu)="redirectFromMegaMenu($event.searchType, $event.searchId, $event.searchName)">
                        </app-mega-menu>
                      </div>
                    </ng-container>
                  </li>
                  <li class="nav-item" [ngClass]="isActiveMenu('resource')" (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div class="nav-link" (click)="isCollapsed = !isCollapsed" [routerLink]="['resource']"><span>Resource Hub</span></div>
                  </li>
                  <li class="nav-item" [ngClass]="isActiveMenu('our-story')" (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div class="nav-link" (click)="isCollapsed = !isCollapsed" [routerLink]="['our-story']"><span>Our Story</span></div>
                  </li>
                  <li class="nav-item" [ngClass]="isActiveMenu('project')" (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div class="nav-link" (click)="isCollapsed = !isCollapsed" [routerLink]="['project']"><span>Feature
                        Projects</span></div>
                  </li>
                  <li class="nav-item" [ngClass]="isActiveMenu('blog')" (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div class="nav-link" (click)="isCollapsed = !isCollapsed"
                       [routerLink]="['blog']"><span>Blog</span></div>
                  </li>
                  <li class="nav-item" [ngClass]="isActiveMenu('tiles-for-smiles')"
                      (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div class="nav-link" (click)="isCollapsed = !isCollapsed" [routerLink]="['tiles-for-smiles']"><span>Tiles for
                        Smiles</span></div>
                  </li>

                  <li class="nav-item"
                  [ngClass]="[isActiveMenu('price-list'),(!isUserAuthenticated ? 'disable-menu': '')]"
                  (mouseenter)="onMouseEnterCloseMegaMenu()">
                    <div *ngIf="!isUserAuthenticated"  class="nav-link" (click)="unAuthenticatePriceListErrorMessage()"><span>Price Lists</span></div>
                    <div *ngIf="isUserAuthenticated" class="nav-link" (click)="isCollapsed = !isCollapsed" [routerLink]="['price-list']"><span>Price Lists</span></div>
              </li>

                  <li class="nav-item d-md-none b-block login-btn-item" *ngIf="!isUserAuthenticated">
                    <button class="btn grey-bg-btn signin-trigger" type="button" (click)="rightSidebarToggleSignIn()"
                            (mouseenter)="onMouseEnterCloseMegaMenu()">
                      <span class="user-name">Sign In</span>
                    </button>
                  </li>
                  <li class="nav-item d-md-none b-block" *ngIf="!isUserAuthenticated">
                    <button class="btn grey-bg-btn signin-trigger singup-btn signup-header-mobile" type="button"
                            (click)="rightSidebarToggleSignUp()">
                      <span class="user-name">Sign Up</span>
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="menu-right-widget" [ngClass]="{'prices-list-icon': (isWhiteIcon() && this.darkHeader == false) }">
          <div class="gatheco-credit-container" *ngIf="isUserAuthenticated && isTradeUser">
            <a class="gatheco-credit" [routerLink]="['/gather-commitment']">
              <img src="{{Constants.S3_BUCKET_URL}}/credit-coin-icon.svg" loading="lazy" alt="Credit Icon" width="22" title="GatherCo Credit"
                   height="22">
              <label class="credit-label"><span class="credit-content">GatherCo Credit </span><strong class="credit">{{
                gathercoCreditCount | currency }}</strong></label>
            </a>
          </div>
          <ng-container *ngIf="!isUserAuthenticated">
            <button class="btn btn-primary grey-bg-btn signin-trigger sign-inbtn" type="button"
                    (click)="rightSidebarToggle()">
              <span class="user-name">Sign In</span>
            </button>
          </ng-container>
          <ng-container *ngIf="isUserAuthenticated">
            <div class="notifications">
              <ul>
                <li *ngIf="isTradeUser">
                  <div class="notification-item assigment">
                    <a [tooltip]="'Dashboard'" [adaptivePosition]="true" [routerLink]="['/account/dashboard']"
                       aria-label="Dashboard Icon">
                      <span class="icon-dashboard"></span>
                    </a>
                  </div>
                </li>
                <li *ngIf="isTradeUser && quotesCount > 0">
                  <div class="notification-item assigment">
                    <a [tooltip]="'Quotes'" [adaptivePosition]="true" [routerLink]="['/account/quotes']"
                       aria-label="Dashboard Icon">
                      <span class="icon-file-alt"></span>
                      <span class="count">{{quotesCount}}</span>
                    </a>
                  </div>
                </li>
                <li *ngIf="isTradeUser && orderCount > 0">
                  <div class="notification-item delivery">
                    <a [tooltip]="'Orders'" [adaptivePosition]="true" [routerLink]="['/account/orders']"
                       aria-label="Delivery Icon" role="link">
                      <span class="icon-delivery-o cartitem"></span>
                      <span class="count" id="cartiteamcount">{{orderCount}}</span>
                    </a>
                  </div>
                </li>
                <li *ngIf="isTradeUser && sampleOrderCount > 0">
                  <div class="notification-item delivery">
                    <span [tooltip]="'Sample Orders'" [adaptivePosition]="true" aria-label="Bookmark Icon"
                       (click)="openSampleOrderSidebar()">
                      <span class="icon-bookmark"></span>
                      <span class="count" id="cartiteamcount">{{ sampleOrderCount }}</span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="user-menu">
              <div class="dropdown" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                <button class="btn btn-primary dropdown-toggle" dropdownToggle type="button" aria-label="Dropdown">
                  <span class="icon-user-line"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                  <li><a class="dropdown-item" [routerLink]="['/account/favourite']" *ngIf="!isTradeUser">Favourites</a>
                  </li>
                  <li><a [routerLink]="['/account']">Setting</a></li>
                  <li><a class="dropdown-item" [routerLink]="['/']" (click)="logout()">Logout</a></li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</header>
