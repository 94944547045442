<div class="tab-content">
  <div class="tab-pane" [ngClass]="{'active': loginTabForm && !resetPasswordTabForm}">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="login_form">
      <div class="content-launch">
        <h4 class="welcome-title">Welcome to Gather Co 2.1</h4>
      </div>
      <div class="login-form panel-tab-section">
        <div class="row">
          <div class="col">
            <label class="form-label">Email <span class="required-field">*</span></label>
            <input class="form-control" #emailAddress (focus)="onFocus($event)" (blur)="onFocus()"
              placeholder="Enter your email"
              [ngClass]="{ 'is-invalid': (loginForm.controls['email'].invalid && loginForm.controls['email'].touched || submitted), 'is-valid' : !(loginForm.controls['email'].invalid)}"
              formControlName="email" type="email" required (change)="onFormFieldClick()" autocomplete="off">
            <span class="invalid-feedback" role="alert"
              *ngIf="(loginForm.controls['email'].invalid && loginForm.controls['email'].touched || submitted)">
              <span *ngIf="f.email.errors?.required">Please enter your email</span>
              <span *ngIf="f.email.errors?.email">Please enter a valid email address</span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label">Password <span class="required-field">*</span></label>
            <input formControlName="password" placeholder="Enter your password"
              [ngClass]="{ 'is-invalid': (loginForm.controls['password'].invalid && loginForm.controls['password'].touched || submitted), 'is-valid' : !(loginForm.controls['password'].invalid)}"
              class="form-control" type="password" required autocomplete="off">
            <span class="invalid-feedback" role="alert"
              *ngIf="(loginForm.controls['password'].invalid && loginForm.controls['password'].touched || submitted)">
              <span *ngIf="f.password.errors?.required">Please enter your password</span>
              <span *ngIf="f.password.errors?.minlength">Please enter at least 6 characters</span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="remember-forgot">
              <div class="rememer-me">
                <input class="form-check-input" type="checkbox" name="remember" id="remember">
                <label for="remember">Remember me</label>
              </div>
              <span class="btn btn-link forgot_password" (click)="switchForgotPassword()">
                Forgot password?
              </span>
            </div>
          </div>
        </div>
        <div class="row form-submit-btn">
          <div class="col">
            <button type="submit" class="btn grey-bordered-btn login-btn">
              Sign in
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col singin-social-btn social-login-icon-button">
            <div class="btn grey-bordered-btn google" (click)="googleLogin()">
              <div class="bordered-inner">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.603" height="16.875" viewBox="0 0 16.603 16.875">
                  <path id="Icon_awesome-google" data-name="Icon awesome-google"
                    d="M16.6,9.2a7.835,7.835,0,0,1-8.165,8.24,8.438,8.438,0,1,1,0-16.875A8.114,8.114,0,0,1,14.1,2.771L11.8,4.979c-3-2.9-8.591-.721-8.591,4.021a5.285,5.285,0,0,0,5.229,5.328,4.563,4.563,0,0,0,4.79-3.637H8.438v-2.9H16.47A7.4,7.4,0,0,1,16.6,9.2Z"
                    transform="translate(0 -0.563)" />
                </svg>
                Sign in with Google
              </div>
            </div>
            <div class="btn grey-bordered-btn facebook" (click)="fbLogin()">
              <div class="bordered-inner">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="16.875" viewBox="0 0 16.875 16.875">
                  <path id="awesome-facebook"
                    d="M15.067,2.25H1.808A1.808,1.808,0,0,0,0,4.058V17.317a1.808,1.808,0,0,0,1.808,1.808h5.17V13.388H4.6v-2.7H6.978V8.629A3.3,3.3,0,0,1,10.506,5a14.376,14.376,0,0,1,2.091.182v2.3H11.42A1.35,1.35,0,0,0,9.9,8.934v1.753h2.591l-.414,2.7H9.9v5.737h5.17a1.808,1.808,0,0,0,1.808-1.808V4.058A1.808,1.808,0,0,0,15.067,2.25Z"
                    transform="translate(0 -2.25)" />
                </svg>
                Sign in with Facebook
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="tab-pane" id="forgotPasswordTabForm" [ngClass]="{'active': forgotPasswordTabForm}">
    <div class="login-form panel-tab-section">
      <form id="forgot_password_form" [formGroup]="forgotPassword" (ngSubmit)="onForgotPasswordSubmit()">
        <div class="row">
          <div class="col">
            <label class="form-label">Email address <span class="required-field">*</span></label>
            <input class="form-control"
              [ngClass]="{ 'is-invalid': (forgotPassword.controls['email'].invalid && forgotPassword.controls['email'].touched || submitted) || ff.email.errors?.apiError, 'is-valid' : !(forgotPassword.controls['email'].invalid)}"
              formControlName="email" type="email" required placeholder="Email address"
              (change)="confirmEmailAddress($event)" (keydown.enter)="confirmEmailAddress($event)">
            <span class="invalid-feedback" role="alert"
              *ngIf="(forgotPassword.controls['email'].invalid && forgotPassword.controls['email'].touched || submitted) || ff.email.errors?.apiError">
              <span *ngIf="ff.email.errors?.required">Please enter email address</span>
              <span *ngIf="ff.email.errors?.email">Please enter a valid email address</span>
              <span *ngIf="ff.email.errors?.apiError">{{ff.email.errors?.apiError}}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="form-label confirm-email-address">Confirm email address <span
                class="required-field">*</span></label>
            <input class="form-control"
              [ngClass]="{ 'is-invalid': (forgotPassword.controls['confirm_email'].invalid && forgotPassword.controls['confirm_email'].touched || submitted) || ff.email.errors?.apiError, 'is-valid' : !(forgotPassword.controls['confirm_email'].invalid)}"
              formControlName="confirm_email" type="email" required placeholder="Confirm email address"
              onpaste="return false" id="confirmemailaddress" autocomplete="off">
            <span class="invalid-feedback confirmn-email-address-4" role="alert">
              <span
                *ngIf="(forgotPassword.controls['confirm_email'].invalid && forgotPassword.controls['confirm_email'].touched || submitted) || ff.confirm_email.errors?.apiError">


                <span *ngIf="ff.confirm_email.errors?.required">Please enter confirm email address</span>
                <span *ngIf="ff.confirm_email.errors?.email">Please enter a valid confirm email
                  address</span>
                <span
                  *ngIf="!ff.confirm_email.errors?.required && !ff.confirm_email?.hasError('pattern') && ff.confirm_email.errors?.confirmEmailNotMatch">Email
                  and Confirm Email does not
                  match</span>
                <span *ngIf="ff.confirm_email.errors?.apiError">{{ff.confirm_email.errors?.apiError}}</span>
              </span>
            </span>
          </div>
        </div>


        <div class="row form-submit-btn mt-4">
          <div class="col singin-social-btn forgot-password-btn-div">
            <button type="submit" class="btn grey-bordered-btn login-btn btn-primary-pass">
              Send Password Reset Link
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col singin-social-btn social-login-icon-button">
            <button type="button" class="btn btn-link grey-bordered-btn back_to_login" (click)="switchLogin()">
              Sign in
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="tab-pane" id="resetPasswordTab" [ngClass]="{'active': resetPasswordTabForm}">
    <div class="login-form panel-tab-section">
      <form id="reset_password_form" [formGroup]="resetPassword" (ngSubmit)="onResetPasswordSubmit()">
        <div class="row">
          <div class="col">
            <label class="form-label">Password <span class="required-field">*</span></label>
            <input formControlName="password"
              [ngClass]="{ 'is-invalid': (resetPassword.controls['password'].invalid && resetPassword.controls['password'].touched || submitted), 'is-valid' : !(resetPassword.controls['password'].invalid)}"
              class="form-control" type="password" required placeholder="Enter password">
            <span class="invalid-feedback" role="alert"
              *ngIf="(resetPassword.controls['password'].invalid && resetPassword.controls['password'].touched || submitted)">
              <span *ngIf="fr.password.errors?.required">Please enter password</span>
              <span *ngIf="fr.password.errors?.minlength">Please enter at least 6 characters</span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label">Confirm Password <span class="required-field">*</span></label>
            <input formControlName="confirm_password"
              [ngClass]="{ 'is-invalid': (resetPassword.controls['confirm_password'].invalid && resetPassword.controls['confirm_password'].touched || submitted), 'is-valid' : !(resetPassword.controls['confirm_password'].invalid)}"
              class="form-control form-control-lg" type="password" required placeholder="Enter confirm password">
            <span class="invalid-feedback" role="alert"
              *ngIf="(resetPassword.controls['confirm_password'].invalid && resetPassword.controls['confirm_password'].touched || submitted)">
              <span *ngIf="fr.confirm_password.errors?.required">Please enter confirm password</span>
              <span
                *ngIf="!fr.confirm_password.errors?.required && fr.confirm_password.errors?.passwordNotMatch">Password
                and confirm password
                does not
                match</span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col singin-social-btn forgot-password-btn-div">
            <button type="submit" class="btn grey-bordered-btn login-btn btn-primary-pass">
              Reset Password
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
